import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const SimpleSection = ({ children, dark, className, ...props }) => {
  return (
    <section
      className={'simple-section' + (dark ? ' dark' : '') + (className ? ' ' + className : '')}
      {...props}
    >
      {children}
    </section>
  );
};

SimpleSection.propTypes = {
  children: PropTypes.node,
  dark: PropTypes.bool,
  style: PropTypes.object,
};

export default SimpleSection;
