import React from 'react';
import PropTypes from 'prop-types';

import SimpleSection from '../simple-section';
import { SecondaryLink } from '../buttons';

import { Languages } from '../../tools/languages';

import './index.scss';

const Discover = ({ dark, language, title }) => {
  if (language === Languages.NL) {
    return (
      <SimpleSection className={'discover' + (dark ? ' dark' : '')}>
        <h3>{title ?? 'Ontdek meer over'}</h3>
        <SecondaryLink to="/solutions/integrated-security/">
          Geïntegreerde Beveiliging
        </SecondaryLink>
        <SecondaryLink to="/solutions/scan-and-detection/">Scan en Detectie</SecondaryLink>
        <br />
        <SecondaryLink to="/solutions/perimeter-security/">Perimeter Beveiliging</SecondaryLink>
        <SecondaryLink to="/support/">Service, Onderhoud &amp; Training</SecondaryLink>
      </SimpleSection>
    );
  }

  return (
    <SimpleSection className={'discover' + (dark ? ' dark' : '')}>
      <h3>{title ?? 'Find out more about'}</h3>
      <SecondaryLink to="/solutions/integrated-security/">Integrated Security</SecondaryLink>
      <SecondaryLink to="/solutions/scan-and-detection/">Scan and Detection</SecondaryLink>
      <br />
      <SecondaryLink to="/solutions/perimeter-security/">Perimeter Security</SecondaryLink>
      <SecondaryLink to="/support/">Service, Maintenance &amp; Training</SecondaryLink>
    </SimpleSection>
  );
};

Discover.propTypes = {
  dark: PropTypes.bool,
  language: PropTypes.string,
};

Discover.defaultProps = {
  language: Languages.EN,
};

export default Discover;
